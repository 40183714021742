html {
	height: 100%;
}

body {
	height: 100%;
}

img {
	width: 100%;
}

.landing {
	position: relative;
	background-size: cover;
	background-position: center;
	height: 100vh;
	margin-top: -24px;
	margin-bottom: -50px;
}

.landing-inner {
	padding-top: 80px;
}

.dark-overlay {
	background-color: rgba(0, 0, 0, 0.7);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.card-form {
	opacity: 0.9;
}

.latest-profiles-img {
	width: 40px;
	height: 40px;
}

.form-control::placeholder {
	color: #bbb !important;
}

.navbar-nav li a {
	line-height: 40px;
}

.profile-image {
	width: 40px;
	margin-right: 5px;
}

/* Landing page logo */
.header img {
	/* float: left; */
	width: 150px;
	height: 150px;
}

.header h1 {
	position: relative;
	top: 18px;
	left: 10px;
}

/* Get rid of outline around bootstrap buttons */
.button:active,
button:active,
.button:focus,
button:focus,
.button:hover,
button:hover {
	border: none !important;
	outline: none !important;
}


